<template>
    <div class="skill-checkbox-list">
        <div class="title-bar title-section">
            <h2>Select Skills To Merge</h2>
            <v-text-field
                :color="colors.PRIMARY"
                class="skill-search-box"
                v-model="searchValue"
                :label="'Search'"
            ></v-text-field>
        </div>
        <div class="skill-checkbox-list-container">
            <v-checkbox
                v-for="skill in alphabeticalSkillList"
                v-bind:key="skill.id"
                v-model="skillsToMerge.skills"
                :label="skill.name"
                :color="colors.PRIMARY"
                :value="skill.id"
                class="skill-checkbox"
            ></v-checkbox>
        </div>
    </div>
</template>

<script>
import colors from "@/colors";
import skillDataAccess from '../../../dataAccess/skillDataAccess';
import _ from "lodash";

export default {
    name: "skillcheckboxlist",
    props: ["skillsToMerge"],
    data() {
        return {
            colors,
            skillList: [],
            selectedSkills: [],
            skillDAO: new skillDataAccess(),
            searchValue: ""
        }
    },
    methods: {
        async initSkillList(){
            try {
                this.skillList = await this.skillDAO.getAllSkills();
            } catch (err) {
                console.log(err);
                return;
            }
        },
        getFilteredSkills() {
            if(!this.searchValue){
                return this.skillList;
            }
            return _.filter(this.skillList, skill => {
                return skill.name.toUpperCase().includes(this.searchValue.toUpperCase());
            });
        }
    },
    mounted: async function() {
        await this.initSkillList()
    },
    computed: {
        alphabeticalSkillList(){
            let filteredSkills = this.getFilteredSkills();
            return _.orderBy(filteredSkills, 'name', 'asc');
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 796px) {
  .skill-checkbox-list {
    width: 100%;
    max-width: inherit;
  }
}

.skill-checkbox-list-container{
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}

.skill-checkbox-list {
  width: 700px;
  box-shadow: 0px 1px 5px #aaaaaa;
  background-color: #fefefe;
}

.skill-checkbox{
    width: 150px;
    flex: 0 1 auto;
}

.title-section {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
}

.skill-search-box {
  margin: 0 20px;
  margin-bottom: -5px;
}
</style>