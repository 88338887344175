<template>
    <div class="skill-cleanup">
        <div class="title-bar">
            <h1>Skill Cleanup</h1>
        </div>
        <div class="cleanup-content">
            <div class="first-column">
                <NewSkill class="flex-child"
                    :mergeModel="mergeModel"/>
                <SkillCheckboxList class="flex-child"
                        :skillsToMerge="skillsToMerge"/>
            </div>
            <div class="finalize-merge flex-child">
                <div class="title-bar">
                    <h1>Finalize Merge</h1>
                </div>
                <div class="finalize-merge-container">
                    <div v-if="skillsToMerge.skills.length == 0">
                        <p>No skills selected. Please select skills to merge.</p>
                    </div>
                    <div v-else>
                        <v-container fluid>
                            <v-layout wrap>
                                <v-checkbox
                                    v-for="skill in skillsToDisplay"
                                    v-bind:key="skill.id"
                                    v-model="skillsToMerge.skills"
                                    :label="skill.name"
                                    :color="colors.PRIMARY"
                                    :value="skill.id"
                                    class="skill-checkbox"
                                ></v-checkbox>
                            </v-layout>
                        </v-container>
                    </div>
                    <v-btn
                        class="white--text create-button"
                        :color="colors.PRIMARY"
                        v-on:click="confirmMergeDialog = true"
                    >Merge</v-btn>
                </div>
            </div>

        </div>

        <v-snackbar
            v-model="snackbarShown"
            :color="snackbarColor"
            :timeout="snackbarTimeout"
            >{{snackbarMessage}}</v-snackbar>
            <v-snackbar
            v-model="snackbarErrorShown"
            :color="snackbarErrorColor"
            :timeout="snackbarTimeout"
            >{{snackbarErrorMessage}}</v-snackbar>

        <v-layout row justify-center>
            <v-dialog v-model="confirmMergeDialog" persistent max-width="400">
                <v-card>
                    <v-card-text class="headline">Are you sure you want to merge the following skills:
                            <p v-for="skill in skillsToDisplay" v-bind:key="skill.id">
                                <b>"{{skill.name}}"</b>
                            </p>
                         Once you merge them, there is no way to unmerge them.</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="purple darken-3" text @click="confirmMergeDialog = false">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="purple darken-3" text @click="merge">Confirm</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </div>
</template>

<script>
import colors from "@/colors";
import NewSkill from './components/NewSkill';
import skillDataAccess from '../../dataAccess/skillDataAccess';
import SkillCheckboxList from './components/SkillCheckboxList';

export default {
    name: 'skillcleanup',
    components: {
        NewSkill,
        SkillCheckboxList
    },
    data() {
        return {
            colors,
            mergeModel: {
                Name: "",
                category: {},
                tags: [],
            },
            skillsToMerge: {
                skills: []
            },
            confirmMergeDialog: false,
            skillDAO: new skillDataAccess(),
            allSkills: [],
            snackbarColor: "#009b12",
            snackbarShown: false,
            snackbarTimeout: 2000,
            snackbarMessage: "Success: Skills Merged",
            snackbarErrorColor: "red",
            snackbarErrorShown: false,
            snackbarErrorMessage: "Error: Unable to Merge Skills",
        }   
    },
    methods: {
        async merge() {
            this.confirmMergeDialog = false;
            if(this.isModelValid()){
                try{
                    await this.skillDAO.mergeSkills(this.mergeModel, this.skillsToDisplay);
                } catch (err) {
                    console.log(err);
                    return;
                }
                this.snackbarShown = true;
                this.resetCleanup();
            }
            else {
                this.snackbarErrorShown = true;
            }
        },
        isModelValid(){
            if(this.mergeModel.Name == ""){
                this.snackbarErrorMessage = "Error: You must include a new skill name.";
                return false;
            }
            else if (this.mergeModel.category.name == undefined){
                this.snackbarErrorMessage = "Error: You must include a skill category.";
                return false;
            }
            else if (this.skillsToMerge.skills.length <=1){
                this.snackbarErrorMessage = "Error: You must include more than one skill to merge.";
                return false;
            }
            else {
                return true;
            }
        },
        resetCleanup(){
            this.mergeModel.Name = "";
            this.mergeModel.category = {};
            this.mergeModel.tags = [];
            this.skillsToMerge.skills = [];
        },
        async getAllSkills() {
            try {
                this.allSkills = await this.skillDAO.getAllSkills();
            } catch (err) {
                console.log(err);
                return;
            }
        }
    },
    mounted: async function() {
        await this.getAllSkills();
    },
    computed: {
        skillsToDisplay(){
            var display = [];
            this.skillsToMerge.skills.forEach(element => {
                this.allSkills.forEach(skill => {
                    if(element == skill.id){
                        display.push({id: skill.id, name: skill.name, category: skill.category, tags: skill.tags});
                    }
                });
            });
            return display;
        }
    }
}
</script>

<style scoped>
.cleanup-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-child {
  margin: auto 20px;
  margin-top: 20px;
  flex: 1 1 content;
}

@media screen and (max-width: 900px) {
  .flex-child {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 content;
  }

  .first-column {
    width: 100%;
  }
}

.first-column {
    display:flex;
    flex-direction: column;
}

.create-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.finalize-merge-container {
  margin: 20px;
  margin-bottom: 0;
}

.finalize-merge {
  max-width: 400px;
  min-width: 400px;
  box-shadow: 0px 1px 5px #aaaaaa;
  background-color: #fefefe;
}

@media screen and (max-width: 900px) {
  .finalize-merge {
    width: 100%;
    height: 100%;
    max-width: inherit;
    min-width: 100px;
  }
}

.skill-checkbox {
    margin-top: 0px;
}
</style>