<template>
    <div class="new-skill-container">
        <div class="title-bar">
            <h1>Enter the New Skills Information</h1>
        </div>
        <v-form class="new-skill-inputs-container" ref="inputform">
            <v-text-field
                class="padded-input"
                :color="colors.PRIMARY"
                :label="'Skill Name'"
                v-model="mergeModel.Name"
                :rules="[v => !!v || 'Skill Name is required.']"
            ></v-text-field>
            <v-autocomplete
                class="padded-input"
                :no-data-text="'No skills found'"
                :color="colors.PRIMARY"
                :label="'Category'"
                :items="tagOptions"
                :item-text="'name'"
                v-model="selectedCategory"
                v-on:change="selectCategory()"
                :rules="[v => !!v || 'Category is required.']"
            ></v-autocomplete>
            <v-combobox
                class="padded-input"
                :color="colors.PRIMARY"
                :label="'Additional Tags'"
                :items="tagOptions"
                :item-text="'name'"
                :item-mergeModel="'id'"
                chips
                clearable
                multiple
                solo
                v-model="mergeModel.tags"
            >
                <template v-slot:selection="data">
                    <v-chip class="white--text" :input-value="data.selected" :color="colors.PRIMARY">
                        <strong>{{data.item.name}}</strong>
                    </v-chip>
                </template>
            </v-combobox>
        </v-form>
    </div>
</template>

<script>
import colors from "@/colors";
import skillDataAccess from "../../../dataAccess/skillDataAccess";
import tagDataAccess from "../../../dataAccess/tagDataAccess";
import _ from 'lodash';

export default {
    name: "newskill",
    data() {
        return {
            colors,
            selectedCategory: null,
            tagItems: [],
            tagOptions: [],
            skillDAO: new skillDataAccess(),
            tagDAO: new tagDataAccess()
        }
    },
    props: ["mergeModel"],
    methods: {
        async initTagOptions(){
            try {
                this.tagOptions = await this.tagDAO.getTags();
            } catch (err) {
                console.log(err);
                return;
            }
        },
        selectCategory() {
            this.mergeModel.category = _.find(
                this.tagOptions,
                tag => tag.name === this.selectedCategory
            );
        }
    },
    computed: {
        formIsValid(){
            return this.$refs.inputform.validate()
        }
    },
    mounted: async function (){
        await this.initTagOptions();
    }
}
</script>

<style scoped>
.new-skill-container {
  width: 700px;
  box-shadow: 0px 1px 5px #aaaaaa;
  background-color: #fefefe;
}

@media screen and (max-width: 900px) {
  .new-skill-container {
    width: 100%;
    max-width: inherit;
  }
}

.new-skill-inputs-container {
  margin: 20px;
  margin-top: 15px;
  margin-bottom: 0px;
}
</style>